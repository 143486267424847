import { findServiceClaimSplitApi as api } from "./claimApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    claimCreateVerify: build.mutation<
      ClaimCreateVerifyApiResponse,
      ClaimCreateVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/claim/verify`,
        method: "POST",
        body: queryArg.v1CreateVerifyRequest,
      }),
    }),
    claimGetVerify: build.mutation<
      ClaimGetVerifyApiResponse,
      ClaimGetVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/claim/verify`,
        method: "PUT",
        body: queryArg.v1GetVerifyRequest,
      }),
    }),
    claimVerifyDiscord: build.mutation<
      ClaimVerifyDiscordApiResponse,
      ClaimVerifyDiscordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/claim/verify/discord`,
        method: "PUT",
        body: queryArg.v1VerifyDiscordRequest,
      }),
    }),
    claimVerifySponsor: build.mutation<
      ClaimVerifySponsorApiResponse,
      ClaimVerifySponsorApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/claim/verify/sponsor`,
        method: "PUT",
        body: queryArg.v1VerifySponsorRequest,
      }),
    }),
    claimVerifyTweets: build.mutation<
      ClaimVerifyTweetsApiResponse,
      ClaimVerifyTweetsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/claim/verify/tweets`,
        method: "PUT",
        body: queryArg.v1VerifyTweetsRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedClaimApi };
export type ClaimCreateVerifyApiResponse =
  /** status 200 A successful response. */ V1CreateVerifyReply;
export type ClaimCreateVerifyApiArg = {
  v1CreateVerifyRequest: V1CreateVerifyRequest;
};
export type ClaimGetVerifyApiResponse =
  /** status 200 A successful response. */ V1GetVerifyReply;
export type ClaimGetVerifyApiArg = {
  v1GetVerifyRequest: V1GetVerifyRequest;
};
export type ClaimVerifyDiscordApiResponse =
  /** status 200 A successful response. */ V1VerifyDiscordReply;
export type ClaimVerifyDiscordApiArg = {
  v1VerifyDiscordRequest: V1VerifyDiscordRequest;
};
export type ClaimVerifySponsorApiResponse =
  /** status 200 A successful response. */ V1VerifySponsorReply;
export type ClaimVerifySponsorApiArg = {
  v1VerifySponsorRequest: V1VerifySponsorRequest;
};
export type ClaimVerifyTweetsApiResponse =
  /** status 200 A successful response. */ V1VerifyTweetsReply;
export type ClaimVerifyTweetsApiArg = {
  v1VerifyTweetsRequest: V1VerifyTweetsRequest;
};
export type V1VerifyStep =
  | "SPONSOR"
  | "TWEETS"
  | "DISCORD"
  | "DISCORD_DONE"
  | "DONE";
export type V1Verify = {
  id?: string;
  tokenId?: string;
  claimAddress?: string;
  step?: V1VerifyStep;
  expiredTime?: string;
};
export type V1CreateVerifyReply = {
  verify?: V1Verify;
};
export type V1SupportChainId =
  | "POLYGON"
  | "POLYGON_MUMBAI"
  | "GOERLI"
  | "MAINNET";
export type V1CreateVerifyRequest = {
  chainId?: V1SupportChainId;
  tokenId?: string;
  address?: string;
};
export type V1GetVerifyReply = {
  verify?: V1Verify;
};
export type V1GetVerifyRequest = {
  verifyId?: string;
};
export type V1VerifyDiscordReply = {
  verify?: V1Verify;
};
export type V1VerifyDiscordRequest = {
  verifyId?: string;
};
export type V1VerifySponsorReply = {
  verify?: V1Verify;
};
export type V1VerifySponsorRequest = {
  verifyId?: string;
};
export type V1VerifyTweetsReply = {
  verify?: V1Verify;
};
export type V1VerifyTweetsRequest = {
  verifyId?: string;
  tweets?: string;
};
export const {
  useClaimCreateVerifyMutation,
  useClaimGetVerifyMutation,
  useClaimVerifyDiscordMutation,
  useClaimVerifySponsorMutation,
  useClaimVerifyTweetsMutation,
} = injectedRtkApi;
