import { findServiceUserSplitApi as api } from "./userApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    userSaveGithubOrgAuth: build.mutation<
      UserSaveGithubOrgAuthApiResponse,
      UserSaveGithubOrgAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/auth/github_org`,
        method: "POST",
        body: queryArg.v1SaveGithubOrgAuthRequest,
      }),
    }),
    userGithubOrgAuth: build.mutation<
      UserGithubOrgAuthApiResponse,
      UserGithubOrgAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/auth/github_org`,
        method: "PUT",
        body: queryArg.v1GithubOrgAuthRequest,
      }),
    }),
    userGetGithubRepoInfo: build.mutation<
      UserGetGithubRepoInfoApiResponse,
      UserGetGithubRepoInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/github_repo_info`,
        method: "POST",
        body: queryArg.v1GetGithubRepoInfoRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedUserApi };
export type UserSaveGithubOrgAuthApiResponse =
  /** status 200 A successful response. */ V1SaveGithubOrgAuthReply;
export type UserSaveGithubOrgAuthApiArg = {
  v1SaveGithubOrgAuthRequest: V1SaveGithubOrgAuthRequest;
};
export type UserGithubOrgAuthApiResponse =
  /** status 200 A successful response. */ V1GithubOrgAuthReply;
export type UserGithubOrgAuthApiArg = {
  v1GithubOrgAuthRequest: V1GithubOrgAuthRequest;
};
export type UserGetGithubRepoInfoApiResponse =
  /** status 200 A successful response. */ V1GetGithubRepoInfoReply;
export type UserGetGithubRepoInfoApiArg = {
  v1GetGithubRepoInfoRequest: V1GetGithubRepoInfoRequest;
};
export type V1SaveGithubOrgAuthReply = {
  state?: string;
};
export type V1SupportChainId =
  | "POLYGON"
  | "POLYGON_MUMBAI"
  | "GOERLI"
  | "MAINNET";
export type V1SaveGithubOrgAuthRequest = {
  chainId?: V1SupportChainId;
  github?: string;
  tokenName?: string;
  nftPrice?: string;
  nftPriceDiscounted?: string;
  multiplyAmount?: string;
  pairTokenName?: string;
};
export type V1GithubOrgAuthReply = {
  chainId?: V1SupportChainId;
  isOwner?: boolean;
  github?: string;
  githubLogo?: string;
  githubStars?: string;
  tokenName?: string;
  nftPrice?: string;
  nftPriceDiscounted?: string;
  multiplyAmount?: string;
  pairTokenName?: string;
};
export type V1GithubOrgAuthRequest = {
  code?: string;
  state?: string;
};
export type V1SymbolCheck = {
  symbol?: string;
  id?: string;
};
export type V1GetGithubRepoInfoReply = {
  orgId?: string;
  stars?: string;
  watchers?: string;
  forks?: string;
  logo?: string;
  title?: string;
  desc?: string;
  type?: string;
  existedId?: string;
  symbolCheck?: V1SymbolCheck[];
};
export type V1GetGithubRepoInfoRequest = {
  chainId?: V1SupportChainId;
  owner?: string;
  repo?: string;
};
export const {
  useUserSaveGithubOrgAuthMutation,
  useUserGithubOrgAuthMutation,
  useUserGetGithubRepoInfoMutation,
} = injectedRtkApi;
