import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type CashHistory = {
  __typename?: 'CashHistory';
  amountOut: Scalars['BigInt'];
  id: Scalars['ID'];
  ospAsset: Scalars['Bytes'];
  outFindAmount: Scalars['BigInt'];
  outOspPositionAmount: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  tokenId: Scalars['BigInt'];
  tokenOut: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type CashHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  and?: InputMaybe<Array<InputMaybe<CashHistory_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<CashHistory_Filter>>>;
  ospAsset?: InputMaybe<Scalars['Bytes']>;
  ospAsset_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospAsset_lt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_lte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  outFindAmount?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  outFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  outOspPositionAmount?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_gt?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_gte?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  outOspPositionAmount_lt?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_lte?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_not?: InputMaybe<Scalars['BigInt']>;
  outOspPositionAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenOut?: InputMaybe<Scalars['Bytes']>;
  tokenOut_contains?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenOut_lt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_lte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum CashHistory_OrderBy {
  AmountOut = 'amountOut',
  Id = 'id',
  OspAsset = 'ospAsset',
  OutFindAmount = 'outFindAmount',
  OutOspPositionAmount = 'outOspPositionAmount',
  Timestamp = 'timestamp',
  TokenId = 'tokenId',
  TokenOut = 'tokenOut',
  User = 'user'
}

export type CollectOspUniswapLpFeeHistory = {
  __typename?: 'CollectOspUniswapLPFeeHistory';
  cTokenAmount: Scalars['BigDecimal'];
  cnft: FindNft;
  cnftowner: Scalars['Bytes'];
  id: Scalars['ID'];
  oTokenAmount: Scalars['BigDecimal'];
  onft: FindNft;
  onftowner: Scalars['Bytes'];
  timestamp: Scalars['BigInt'];
};

export type CollectOspUniswapLpFeeHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CollectOspUniswapLpFeeHistory_Filter>>>;
  cTokenAmount?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  cTokenAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  cTokenAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  cnft?: InputMaybe<Scalars['String']>;
  cnft_?: InputMaybe<FindNft_Filter>;
  cnft_contains?: InputMaybe<Scalars['String']>;
  cnft_contains_nocase?: InputMaybe<Scalars['String']>;
  cnft_ends_with?: InputMaybe<Scalars['String']>;
  cnft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_gt?: InputMaybe<Scalars['String']>;
  cnft_gte?: InputMaybe<Scalars['String']>;
  cnft_in?: InputMaybe<Array<Scalars['String']>>;
  cnft_lt?: InputMaybe<Scalars['String']>;
  cnft_lte?: InputMaybe<Scalars['String']>;
  cnft_not?: InputMaybe<Scalars['String']>;
  cnft_not_contains?: InputMaybe<Scalars['String']>;
  cnft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  cnft_not_ends_with?: InputMaybe<Scalars['String']>;
  cnft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_not_in?: InputMaybe<Array<Scalars['String']>>;
  cnft_not_starts_with?: InputMaybe<Scalars['String']>;
  cnft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_starts_with?: InputMaybe<Scalars['String']>;
  cnft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  cnftowner?: InputMaybe<Scalars['Bytes']>;
  cnftowner_contains?: InputMaybe<Scalars['Bytes']>;
  cnftowner_gt?: InputMaybe<Scalars['Bytes']>;
  cnftowner_gte?: InputMaybe<Scalars['Bytes']>;
  cnftowner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cnftowner_lt?: InputMaybe<Scalars['Bytes']>;
  cnftowner_lte?: InputMaybe<Scalars['Bytes']>;
  cnftowner_not?: InputMaybe<Scalars['Bytes']>;
  cnftowner_not_contains?: InputMaybe<Scalars['Bytes']>;
  cnftowner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  oTokenAmount?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  oTokenAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  oTokenAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  onft?: InputMaybe<Scalars['String']>;
  onft_?: InputMaybe<FindNft_Filter>;
  onft_contains?: InputMaybe<Scalars['String']>;
  onft_contains_nocase?: InputMaybe<Scalars['String']>;
  onft_ends_with?: InputMaybe<Scalars['String']>;
  onft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  onft_gt?: InputMaybe<Scalars['String']>;
  onft_gte?: InputMaybe<Scalars['String']>;
  onft_in?: InputMaybe<Array<Scalars['String']>>;
  onft_lt?: InputMaybe<Scalars['String']>;
  onft_lte?: InputMaybe<Scalars['String']>;
  onft_not?: InputMaybe<Scalars['String']>;
  onft_not_contains?: InputMaybe<Scalars['String']>;
  onft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  onft_not_ends_with?: InputMaybe<Scalars['String']>;
  onft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  onft_not_in?: InputMaybe<Array<Scalars['String']>>;
  onft_not_starts_with?: InputMaybe<Scalars['String']>;
  onft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  onft_starts_with?: InputMaybe<Scalars['String']>;
  onft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  onftowner?: InputMaybe<Scalars['Bytes']>;
  onftowner_contains?: InputMaybe<Scalars['Bytes']>;
  onftowner_gt?: InputMaybe<Scalars['Bytes']>;
  onftowner_gte?: InputMaybe<Scalars['Bytes']>;
  onftowner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onftowner_lt?: InputMaybe<Scalars['Bytes']>;
  onftowner_lte?: InputMaybe<Scalars['Bytes']>;
  onftowner_not?: InputMaybe<Scalars['Bytes']>;
  onftowner_not_contains?: InputMaybe<Scalars['Bytes']>;
  onftowner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<CollectOspUniswapLpFeeHistory_Filter>>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum CollectOspUniswapLpFeeHistory_OrderBy {
  CTokenAmount = 'cTokenAmount',
  Cnft = 'cnft',
  CnftCollectedIncomeTokenAmount = 'cnft__collectedIncomeTokenAmount',
  CnftId = 'cnft__id',
  CnftIsCnft = 'cnft__isCnft',
  CnftName = 'cnft__name',
  CnftOspToken = 'cnft__ospToken',
  CnftOwner = 'cnft__owner',
  CnftPercent = 'cnft__percent',
  CnftProjectId = 'cnft__projectId',
  CnftStars = 'cnft__stars',
  CnftSymbol = 'cnft__symbol',
  Cnftowner = 'cnftowner',
  Id = 'id',
  OTokenAmount = 'oTokenAmount',
  Onft = 'onft',
  OnftCollectedIncomeTokenAmount = 'onft__collectedIncomeTokenAmount',
  OnftId = 'onft__id',
  OnftIsCnft = 'onft__isCnft',
  OnftName = 'onft__name',
  OnftOspToken = 'onft__ospToken',
  OnftOwner = 'onft__owner',
  OnftPercent = 'onft__percent',
  OnftProjectId = 'onft__projectId',
  OnftStars = 'onft__stars',
  OnftSymbol = 'onft__symbol',
  Onftowner = 'onftowner',
  Timestamp = 'timestamp'
}

export type FindNft = {
  __typename?: 'FindNFT';
  collectedIncomeTokenAmount: Scalars['BigDecimal'];
  id: Scalars['ID'];
  isCnft: Scalars['Boolean'];
  name: Scalars['String'];
  ospToken: Scalars['Bytes'];
  owner: Scalars['Bytes'];
  percent: Scalars['BigInt'];
  projectId: Scalars['String'];
  stars: Scalars['BigInt'];
  symbol: Scalars['String'];
};

export type FindNft_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FindNft_Filter>>>;
  collectedIncomeTokenAmount?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  collectedIncomeTokenAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  collectedIncomeTokenAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isCnft?: InputMaybe<Scalars['Boolean']>;
  isCnft_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isCnft_not?: InputMaybe<Scalars['Boolean']>;
  isCnft_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<FindNft_Filter>>>;
  ospToken?: InputMaybe<Scalars['Bytes']>;
  ospToken_contains?: InputMaybe<Scalars['Bytes']>;
  ospToken_gt?: InputMaybe<Scalars['Bytes']>;
  ospToken_gte?: InputMaybe<Scalars['Bytes']>;
  ospToken_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospToken_lt?: InputMaybe<Scalars['Bytes']>;
  ospToken_lte?: InputMaybe<Scalars['Bytes']>;
  ospToken_not?: InputMaybe<Scalars['Bytes']>;
  ospToken_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospToken_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner?: InputMaybe<Scalars['Bytes']>;
  owner_contains?: InputMaybe<Scalars['Bytes']>;
  owner_gt?: InputMaybe<Scalars['Bytes']>;
  owner_gte?: InputMaybe<Scalars['Bytes']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_lt?: InputMaybe<Scalars['Bytes']>;
  owner_lte?: InputMaybe<Scalars['Bytes']>;
  owner_not?: InputMaybe<Scalars['Bytes']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  percent?: InputMaybe<Scalars['BigInt']>;
  percent_gt?: InputMaybe<Scalars['BigInt']>;
  percent_gte?: InputMaybe<Scalars['BigInt']>;
  percent_in?: InputMaybe<Array<Scalars['BigInt']>>;
  percent_lt?: InputMaybe<Scalars['BigInt']>;
  percent_lte?: InputMaybe<Scalars['BigInt']>;
  percent_not?: InputMaybe<Scalars['BigInt']>;
  percent_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  projectId?: InputMaybe<Scalars['String']>;
  projectId_contains?: InputMaybe<Scalars['String']>;
  projectId_contains_nocase?: InputMaybe<Scalars['String']>;
  projectId_ends_with?: InputMaybe<Scalars['String']>;
  projectId_ends_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_gt?: InputMaybe<Scalars['String']>;
  projectId_gte?: InputMaybe<Scalars['String']>;
  projectId_in?: InputMaybe<Array<Scalars['String']>>;
  projectId_lt?: InputMaybe<Scalars['String']>;
  projectId_lte?: InputMaybe<Scalars['String']>;
  projectId_not?: InputMaybe<Scalars['String']>;
  projectId_not_contains?: InputMaybe<Scalars['String']>;
  projectId_not_contains_nocase?: InputMaybe<Scalars['String']>;
  projectId_not_ends_with?: InputMaybe<Scalars['String']>;
  projectId_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_not_in?: InputMaybe<Array<Scalars['String']>>;
  projectId_not_starts_with?: InputMaybe<Scalars['String']>;
  projectId_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_starts_with?: InputMaybe<Scalars['String']>;
  projectId_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stars?: InputMaybe<Scalars['BigInt']>;
  stars_gt?: InputMaybe<Scalars['BigInt']>;
  stars_gte?: InputMaybe<Scalars['BigInt']>;
  stars_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stars_lt?: InputMaybe<Scalars['BigInt']>;
  stars_lte?: InputMaybe<Scalars['BigInt']>;
  stars_not?: InputMaybe<Scalars['BigInt']>;
  stars_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum FindNft_OrderBy {
  CollectedIncomeTokenAmount = 'collectedIncomeTokenAmount',
  Id = 'id',
  IsCnft = 'isCnft',
  Name = 'name',
  OspToken = 'ospToken',
  Owner = 'owner',
  Percent = 'percent',
  ProjectId = 'projectId',
  Stars = 'stars',
  Symbol = 'symbol'
}

export type FinderInfo = {
  __typename?: 'FinderInfo';
  balance: Scalars['BigInt'];
  finder: Scalars['Bytes'];
  id: Scalars['ID'];
  token: Scalars['Bytes'];
};

export type FinderInfo_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FinderInfo_Filter>>>;
  balance?: InputMaybe<Scalars['BigInt']>;
  balance_gt?: InputMaybe<Scalars['BigInt']>;
  balance_gte?: InputMaybe<Scalars['BigInt']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  balance_lt?: InputMaybe<Scalars['BigInt']>;
  balance_lte?: InputMaybe<Scalars['BigInt']>;
  balance_not?: InputMaybe<Scalars['BigInt']>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  finder?: InputMaybe<Scalars['Bytes']>;
  finder_contains?: InputMaybe<Scalars['Bytes']>;
  finder_gt?: InputMaybe<Scalars['Bytes']>;
  finder_gte?: InputMaybe<Scalars['Bytes']>;
  finder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  finder_lt?: InputMaybe<Scalars['Bytes']>;
  finder_lte?: InputMaybe<Scalars['Bytes']>;
  finder_not?: InputMaybe<Scalars['Bytes']>;
  finder_not_contains?: InputMaybe<Scalars['Bytes']>;
  finder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<FinderInfo_Filter>>>;
  token?: InputMaybe<Scalars['Bytes']>;
  token_contains?: InputMaybe<Scalars['Bytes']>;
  token_gt?: InputMaybe<Scalars['Bytes']>;
  token_gte?: InputMaybe<Scalars['Bytes']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_lt?: InputMaybe<Scalars['Bytes']>;
  token_lte?: InputMaybe<Scalars['Bytes']>;
  token_not?: InputMaybe<Scalars['Bytes']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum FinderInfo_OrderBy {
  Balance = 'balance',
  Finder = 'finder',
  Id = 'id',
  Token = 'token'
}

export type FinderStat = {
  __typename?: 'FinderStat';
  finderCount: Scalars['BigInt'];
  finderCountWithFactory: Scalars['BigInt'];
  id: Scalars['ID'];
};

export type FinderStat_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FinderStat_Filter>>>;
  finderCount?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_gt?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_gte?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_in?: InputMaybe<Array<Scalars['BigInt']>>;
  finderCountWithFactory_lt?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_lte?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_not?: InputMaybe<Scalars['BigInt']>;
  finderCountWithFactory_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  finderCount_gt?: InputMaybe<Scalars['BigInt']>;
  finderCount_gte?: InputMaybe<Scalars['BigInt']>;
  finderCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  finderCount_lt?: InputMaybe<Scalars['BigInt']>;
  finderCount_lte?: InputMaybe<Scalars['BigInt']>;
  finderCount_not?: InputMaybe<Scalars['BigInt']>;
  finderCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<FinderStat_Filter>>>;
};

export enum FinderStat_OrderBy {
  FinderCount = 'finderCount',
  FinderCountWithFactory = 'finderCountWithFactory',
  Id = 'id'
}

export type MortgageHistory = {
  __typename?: 'MortgageHistory';
  amountOut: Scalars['BigInt'];
  feeFindAmount: Scalars['BigInt'];
  id: Scalars['ID'];
  inOspAmount: Scalars['BigInt'];
  ospAsset: Scalars['Bytes'];
  outFindAmount: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  tokenId: Scalars['BigInt'];
  tokenOut: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type MortgageHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  and?: InputMaybe<Array<InputMaybe<MortgageHistory_Filter>>>;
  feeFindAmount?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  inOspAmount?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_gt?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_gte?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  inOspAmount_lt?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_lte?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_not?: InputMaybe<Scalars['BigInt']>;
  inOspAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<MortgageHistory_Filter>>>;
  ospAsset?: InputMaybe<Scalars['Bytes']>;
  ospAsset_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospAsset_lt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_lte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  outFindAmount?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  outFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  outFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenOut?: InputMaybe<Scalars['Bytes']>;
  tokenOut_contains?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenOut_lt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_lte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum MortgageHistory_OrderBy {
  AmountOut = 'amountOut',
  FeeFindAmount = 'feeFindAmount',
  Id = 'id',
  InOspAmount = 'inOspAmount',
  OspAsset = 'ospAsset',
  OutFindAmount = 'outFindAmount',
  Timestamp = 'timestamp',
  TokenId = 'tokenId',
  TokenOut = 'tokenOut',
  User = 'user'
}

export type MultiplyHistory = {
  __typename?: 'MultiplyHistory';
  AllFindAmount: Scalars['BigInt'];
  amountNeedPay: Scalars['BigInt'];
  feeFindAmount: Scalars['BigInt'];
  id: Scalars['ID'];
  ospAmount: Scalars['BigInt'];
  ospAsset: Scalars['Bytes'];
  payFindAmount: Scalars['BigInt'];
  sender: Scalars['Bytes'];
  timestamp: Scalars['BigInt'];
  tokenId: Scalars['BigInt'];
  tokenPay: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type MultiplyHistory_Filter = {
  AllFindAmount?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  AllFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  AllFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountNeedPay?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_gt?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_gte?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountNeedPay_lt?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_lte?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_not?: InputMaybe<Scalars['BigInt']>;
  amountNeedPay_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  and?: InputMaybe<Array<InputMaybe<MultiplyHistory_Filter>>>;
  feeFindAmount?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  feeFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<MultiplyHistory_Filter>>>;
  ospAmount?: InputMaybe<Scalars['BigInt']>;
  ospAmount_gt?: InputMaybe<Scalars['BigInt']>;
  ospAmount_gte?: InputMaybe<Scalars['BigInt']>;
  ospAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ospAmount_lt?: InputMaybe<Scalars['BigInt']>;
  ospAmount_lte?: InputMaybe<Scalars['BigInt']>;
  ospAmount_not?: InputMaybe<Scalars['BigInt']>;
  ospAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ospAsset?: InputMaybe<Scalars['Bytes']>;
  ospAsset_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospAsset_lt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_lte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  payFindAmount?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  payFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  sender?: InputMaybe<Scalars['Bytes']>;
  sender_contains?: InputMaybe<Scalars['Bytes']>;
  sender_gt?: InputMaybe<Scalars['Bytes']>;
  sender_gte?: InputMaybe<Scalars['Bytes']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']>>;
  sender_lt?: InputMaybe<Scalars['Bytes']>;
  sender_lte?: InputMaybe<Scalars['Bytes']>;
  sender_not?: InputMaybe<Scalars['Bytes']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenPay?: InputMaybe<Scalars['Bytes']>;
  tokenPay_contains?: InputMaybe<Scalars['Bytes']>;
  tokenPay_gt?: InputMaybe<Scalars['Bytes']>;
  tokenPay_gte?: InputMaybe<Scalars['Bytes']>;
  tokenPay_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenPay_lt?: InputMaybe<Scalars['Bytes']>;
  tokenPay_lte?: InputMaybe<Scalars['Bytes']>;
  tokenPay_not?: InputMaybe<Scalars['Bytes']>;
  tokenPay_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenPay_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum MultiplyHistory_OrderBy {
  AllFindAmount = 'AllFindAmount',
  AmountNeedPay = 'amountNeedPay',
  FeeFindAmount = 'feeFindAmount',
  Id = 'id',
  OspAmount = 'ospAmount',
  OspAsset = 'ospAsset',
  PayFindAmount = 'payFindAmount',
  Sender = 'sender',
  Timestamp = 'timestamp',
  TokenId = 'tokenId',
  TokenPay = 'tokenPay',
  User = 'user'
}

export type OspPool = {
  __typename?: 'OSPPool';
  id: Scalars['ID'];
  ospIndex: Scalars['Int'];
  ospToken: Scalars['Bytes'];
  token0: Scalars['Bytes'];
  token1: Scalars['Bytes'];
};

export type OspPool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OspPool_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<OspPool_Filter>>>;
  ospIndex?: InputMaybe<Scalars['Int']>;
  ospIndex_gt?: InputMaybe<Scalars['Int']>;
  ospIndex_gte?: InputMaybe<Scalars['Int']>;
  ospIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  ospIndex_lt?: InputMaybe<Scalars['Int']>;
  ospIndex_lte?: InputMaybe<Scalars['Int']>;
  ospIndex_not?: InputMaybe<Scalars['Int']>;
  ospIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  ospToken?: InputMaybe<Scalars['Bytes']>;
  ospToken_contains?: InputMaybe<Scalars['Bytes']>;
  ospToken_gt?: InputMaybe<Scalars['Bytes']>;
  ospToken_gte?: InputMaybe<Scalars['Bytes']>;
  ospToken_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospToken_lt?: InputMaybe<Scalars['Bytes']>;
  ospToken_lte?: InputMaybe<Scalars['Bytes']>;
  ospToken_not?: InputMaybe<Scalars['Bytes']>;
  ospToken_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospToken_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token0?: InputMaybe<Scalars['Bytes']>;
  token0_contains?: InputMaybe<Scalars['Bytes']>;
  token0_gt?: InputMaybe<Scalars['Bytes']>;
  token0_gte?: InputMaybe<Scalars['Bytes']>;
  token0_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token0_lt?: InputMaybe<Scalars['Bytes']>;
  token0_lte?: InputMaybe<Scalars['Bytes']>;
  token0_not?: InputMaybe<Scalars['Bytes']>;
  token0_not_contains?: InputMaybe<Scalars['Bytes']>;
  token0_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token1?: InputMaybe<Scalars['Bytes']>;
  token1_contains?: InputMaybe<Scalars['Bytes']>;
  token1_gt?: InputMaybe<Scalars['Bytes']>;
  token1_gte?: InputMaybe<Scalars['Bytes']>;
  token1_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token1_lt?: InputMaybe<Scalars['Bytes']>;
  token1_lte?: InputMaybe<Scalars['Bytes']>;
  token1_not?: InputMaybe<Scalars['Bytes']>;
  token1_not_contains?: InputMaybe<Scalars['Bytes']>;
  token1_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum OspPool_OrderBy {
  Id = 'id',
  OspIndex = 'ospIndex',
  OspToken = 'ospToken',
  Token0 = 'token0',
  Token1 = 'token1'
}

export type OperationHistory = {
  __typename?: 'OperationHistory';
  cash?: Maybe<CashHistory>;
  id: Scalars['ID'];
  mortgage?: Maybe<MortgageHistory>;
  multiply?: Maybe<MultiplyHistory>;
  redeem?: Maybe<RedeemHistory>;
  timestamp: Scalars['BigInt'];
  type: Scalars['String'];
  user: Scalars['Bytes'];
};

export type OperationHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OperationHistory_Filter>>>;
  cash?: InputMaybe<Scalars['String']>;
  cash_?: InputMaybe<CashHistory_Filter>;
  cash_contains?: InputMaybe<Scalars['String']>;
  cash_contains_nocase?: InputMaybe<Scalars['String']>;
  cash_ends_with?: InputMaybe<Scalars['String']>;
  cash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cash_gt?: InputMaybe<Scalars['String']>;
  cash_gte?: InputMaybe<Scalars['String']>;
  cash_in?: InputMaybe<Array<Scalars['String']>>;
  cash_lt?: InputMaybe<Scalars['String']>;
  cash_lte?: InputMaybe<Scalars['String']>;
  cash_not?: InputMaybe<Scalars['String']>;
  cash_not_contains?: InputMaybe<Scalars['String']>;
  cash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  cash_not_ends_with?: InputMaybe<Scalars['String']>;
  cash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cash_not_in?: InputMaybe<Array<Scalars['String']>>;
  cash_not_starts_with?: InputMaybe<Scalars['String']>;
  cash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  cash_starts_with?: InputMaybe<Scalars['String']>;
  cash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  mortgage?: InputMaybe<Scalars['String']>;
  mortgage_?: InputMaybe<MortgageHistory_Filter>;
  mortgage_contains?: InputMaybe<Scalars['String']>;
  mortgage_contains_nocase?: InputMaybe<Scalars['String']>;
  mortgage_ends_with?: InputMaybe<Scalars['String']>;
  mortgage_ends_with_nocase?: InputMaybe<Scalars['String']>;
  mortgage_gt?: InputMaybe<Scalars['String']>;
  mortgage_gte?: InputMaybe<Scalars['String']>;
  mortgage_in?: InputMaybe<Array<Scalars['String']>>;
  mortgage_lt?: InputMaybe<Scalars['String']>;
  mortgage_lte?: InputMaybe<Scalars['String']>;
  mortgage_not?: InputMaybe<Scalars['String']>;
  mortgage_not_contains?: InputMaybe<Scalars['String']>;
  mortgage_not_contains_nocase?: InputMaybe<Scalars['String']>;
  mortgage_not_ends_with?: InputMaybe<Scalars['String']>;
  mortgage_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  mortgage_not_in?: InputMaybe<Array<Scalars['String']>>;
  mortgage_not_starts_with?: InputMaybe<Scalars['String']>;
  mortgage_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  mortgage_starts_with?: InputMaybe<Scalars['String']>;
  mortgage_starts_with_nocase?: InputMaybe<Scalars['String']>;
  multiply?: InputMaybe<Scalars['String']>;
  multiply_?: InputMaybe<MultiplyHistory_Filter>;
  multiply_contains?: InputMaybe<Scalars['String']>;
  multiply_contains_nocase?: InputMaybe<Scalars['String']>;
  multiply_ends_with?: InputMaybe<Scalars['String']>;
  multiply_ends_with_nocase?: InputMaybe<Scalars['String']>;
  multiply_gt?: InputMaybe<Scalars['String']>;
  multiply_gte?: InputMaybe<Scalars['String']>;
  multiply_in?: InputMaybe<Array<Scalars['String']>>;
  multiply_lt?: InputMaybe<Scalars['String']>;
  multiply_lte?: InputMaybe<Scalars['String']>;
  multiply_not?: InputMaybe<Scalars['String']>;
  multiply_not_contains?: InputMaybe<Scalars['String']>;
  multiply_not_contains_nocase?: InputMaybe<Scalars['String']>;
  multiply_not_ends_with?: InputMaybe<Scalars['String']>;
  multiply_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  multiply_not_in?: InputMaybe<Array<Scalars['String']>>;
  multiply_not_starts_with?: InputMaybe<Scalars['String']>;
  multiply_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  multiply_starts_with?: InputMaybe<Scalars['String']>;
  multiply_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<OperationHistory_Filter>>>;
  redeem?: InputMaybe<Scalars['String']>;
  redeem_?: InputMaybe<RedeemHistory_Filter>;
  redeem_contains?: InputMaybe<Scalars['String']>;
  redeem_contains_nocase?: InputMaybe<Scalars['String']>;
  redeem_ends_with?: InputMaybe<Scalars['String']>;
  redeem_ends_with_nocase?: InputMaybe<Scalars['String']>;
  redeem_gt?: InputMaybe<Scalars['String']>;
  redeem_gte?: InputMaybe<Scalars['String']>;
  redeem_in?: InputMaybe<Array<Scalars['String']>>;
  redeem_lt?: InputMaybe<Scalars['String']>;
  redeem_lte?: InputMaybe<Scalars['String']>;
  redeem_not?: InputMaybe<Scalars['String']>;
  redeem_not_contains?: InputMaybe<Scalars['String']>;
  redeem_not_contains_nocase?: InputMaybe<Scalars['String']>;
  redeem_not_ends_with?: InputMaybe<Scalars['String']>;
  redeem_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  redeem_not_in?: InputMaybe<Array<Scalars['String']>>;
  redeem_not_starts_with?: InputMaybe<Scalars['String']>;
  redeem_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  redeem_starts_with?: InputMaybe<Scalars['String']>;
  redeem_starts_with_nocase?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum OperationHistory_OrderBy {
  Cash = 'cash',
  CashAmountOut = 'cash__amountOut',
  CashId = 'cash__id',
  CashOspAsset = 'cash__ospAsset',
  CashOutFindAmount = 'cash__outFindAmount',
  CashOutOspPositionAmount = 'cash__outOspPositionAmount',
  CashTimestamp = 'cash__timestamp',
  CashTokenId = 'cash__tokenId',
  CashTokenOut = 'cash__tokenOut',
  CashUser = 'cash__user',
  Id = 'id',
  Mortgage = 'mortgage',
  MortgageAmountOut = 'mortgage__amountOut',
  MortgageFeeFindAmount = 'mortgage__feeFindAmount',
  MortgageId = 'mortgage__id',
  MortgageInOspAmount = 'mortgage__inOspAmount',
  MortgageOspAsset = 'mortgage__ospAsset',
  MortgageOutFindAmount = 'mortgage__outFindAmount',
  MortgageTimestamp = 'mortgage__timestamp',
  MortgageTokenId = 'mortgage__tokenId',
  MortgageTokenOut = 'mortgage__tokenOut',
  MortgageUser = 'mortgage__user',
  Multiply = 'multiply',
  MultiplyAllFindAmount = 'multiply__AllFindAmount',
  MultiplyAmountNeedPay = 'multiply__amountNeedPay',
  MultiplyFeeFindAmount = 'multiply__feeFindAmount',
  MultiplyId = 'multiply__id',
  MultiplyOspAmount = 'multiply__ospAmount',
  MultiplyOspAsset = 'multiply__ospAsset',
  MultiplyPayFindAmount = 'multiply__payFindAmount',
  MultiplySender = 'multiply__sender',
  MultiplyTimestamp = 'multiply__timestamp',
  MultiplyTokenId = 'multiply__tokenId',
  MultiplyTokenPay = 'multiply__tokenPay',
  MultiplyUser = 'multiply__user',
  Redeem = 'redeem',
  RedeemAmountIn = 'redeem__amountIn',
  RedeemId = 'redeem__id',
  RedeemInFindAmount = 'redeem__inFindAmount',
  RedeemOspAsset = 'redeem__ospAsset',
  RedeemOutOspAmount = 'redeem__outOspAmount',
  RedeemTimestamp = 'redeem__timestamp',
  RedeemTokenId = 'redeem__tokenId',
  RedeemTokenIn = 'redeem__tokenIn',
  RedeemUser = 'redeem__user',
  Timestamp = 'timestamp',
  Type = 'type',
  User = 'user'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  cashHistories: Array<CashHistory>;
  cashHistory?: Maybe<CashHistory>;
  collectOspUniswapLPFeeHistories: Array<CollectOspUniswapLpFeeHistory>;
  collectOspUniswapLPFeeHistory?: Maybe<CollectOspUniswapLpFeeHistory>;
  findNFT?: Maybe<FindNft>;
  findNFTs: Array<FindNft>;
  finderInfo?: Maybe<FinderInfo>;
  finderInfos: Array<FinderInfo>;
  finderStat?: Maybe<FinderStat>;
  finderStats: Array<FinderStat>;
  mortgageHistories: Array<MortgageHistory>;
  mortgageHistory?: Maybe<MortgageHistory>;
  multiplyHistories: Array<MultiplyHistory>;
  multiplyHistory?: Maybe<MultiplyHistory>;
  operationHistories: Array<OperationHistory>;
  operationHistory?: Maybe<OperationHistory>;
  osppool?: Maybe<OspPool>;
  osppools: Array<OspPool>;
  redeemHistories: Array<RedeemHistory>;
  redeemHistory?: Maybe<RedeemHistory>;
  stat?: Maybe<Stat>;
  stats: Array<Stat>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryCashHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CashHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CashHistory_Filter>;
};


export type QueryCashHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCollectOspUniswapLpFeeHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CollectOspUniswapLpFeeHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CollectOspUniswapLpFeeHistory_Filter>;
};


export type QueryCollectOspUniswapLpFeeHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFindNftArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFindNfTsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FindNft_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FindNft_Filter>;
};


export type QueryFinderInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFinderInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FinderInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FinderInfo_Filter>;
};


export type QueryFinderStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFinderStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FinderStat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FinderStat_Filter>;
};


export type QueryMortgageHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MortgageHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MortgageHistory_Filter>;
};


export type QueryMortgageHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMultiplyHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MultiplyHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MultiplyHistory_Filter>;
};


export type QueryMultiplyHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOperationHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OperationHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OperationHistory_Filter>;
};


export type QueryOperationHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOsppoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOsppoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OspPool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OspPool_Filter>;
};


export type QueryRedeemHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RedeemHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RedeemHistory_Filter>;
};


export type QueryRedeemHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Stat_Filter>;
};


export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type RedeemHistory = {
  __typename?: 'RedeemHistory';
  amountIn: Scalars['BigInt'];
  id: Scalars['ID'];
  inFindAmount: Scalars['BigInt'];
  ospAsset: Scalars['Bytes'];
  outOspAmount: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  tokenId: Scalars['BigInt'];
  tokenIn: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type RedeemHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amountIn?: InputMaybe<Scalars['BigInt']>;
  amountIn_gt?: InputMaybe<Scalars['BigInt']>;
  amountIn_gte?: InputMaybe<Scalars['BigInt']>;
  amountIn_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountIn_lt?: InputMaybe<Scalars['BigInt']>;
  amountIn_lte?: InputMaybe<Scalars['BigInt']>;
  amountIn_not?: InputMaybe<Scalars['BigInt']>;
  amountIn_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  and?: InputMaybe<Array<InputMaybe<RedeemHistory_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  inFindAmount?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_gt?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_gte?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  inFindAmount_lt?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_lte?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_not?: InputMaybe<Scalars['BigInt']>;
  inFindAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<RedeemHistory_Filter>>>;
  ospAsset?: InputMaybe<Scalars['Bytes']>;
  ospAsset_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_gte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  ospAsset_lt?: InputMaybe<Scalars['Bytes']>;
  ospAsset_lte?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  ospAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  outOspAmount?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_gt?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_gte?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  outOspAmount_lt?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_lte?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_not?: InputMaybe<Scalars['BigInt']>;
  outOspAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIn?: InputMaybe<Scalars['Bytes']>;
  tokenIn_contains?: InputMaybe<Scalars['Bytes']>;
  tokenIn_gt?: InputMaybe<Scalars['Bytes']>;
  tokenIn_gte?: InputMaybe<Scalars['Bytes']>;
  tokenIn_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenIn_lt?: InputMaybe<Scalars['Bytes']>;
  tokenIn_lte?: InputMaybe<Scalars['Bytes']>;
  tokenIn_not?: InputMaybe<Scalars['Bytes']>;
  tokenIn_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenIn_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum RedeemHistory_OrderBy {
  AmountIn = 'amountIn',
  Id = 'id',
  InFindAmount = 'inFindAmount',
  OspAsset = 'ospAsset',
  OutOspAmount = 'outOspAmount',
  Timestamp = 'timestamp',
  TokenId = 'tokenId',
  TokenIn = 'tokenIn',
  User = 'user'
}

export type Stat = {
  __typename?: 'Stat';
  id: Scalars['ID'];
  ospCount: Scalars['BigInt'];
};

export type Stat_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Stat_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<Stat_Filter>>>;
  ospCount?: InputMaybe<Scalars['BigInt']>;
  ospCount_gt?: InputMaybe<Scalars['BigInt']>;
  ospCount_gte?: InputMaybe<Scalars['BigInt']>;
  ospCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ospCount_lt?: InputMaybe<Scalars['BigInt']>;
  ospCount_lte?: InputMaybe<Scalars['BigInt']>;
  ospCount_not?: InputMaybe<Scalars['BigInt']>;
  ospCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Stat_OrderBy {
  Id = 'id',
  OspCount = 'ospCount'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  cashHistories: Array<CashHistory>;
  cashHistory?: Maybe<CashHistory>;
  collectOspUniswapLPFeeHistories: Array<CollectOspUniswapLpFeeHistory>;
  collectOspUniswapLPFeeHistory?: Maybe<CollectOspUniswapLpFeeHistory>;
  findNFT?: Maybe<FindNft>;
  findNFTs: Array<FindNft>;
  finderInfo?: Maybe<FinderInfo>;
  finderInfos: Array<FinderInfo>;
  finderStat?: Maybe<FinderStat>;
  finderStats: Array<FinderStat>;
  mortgageHistories: Array<MortgageHistory>;
  mortgageHistory?: Maybe<MortgageHistory>;
  multiplyHistories: Array<MultiplyHistory>;
  multiplyHistory?: Maybe<MultiplyHistory>;
  operationHistories: Array<OperationHistory>;
  operationHistory?: Maybe<OperationHistory>;
  osppool?: Maybe<OspPool>;
  osppools: Array<OspPool>;
  redeemHistories: Array<RedeemHistory>;
  redeemHistory?: Maybe<RedeemHistory>;
  stat?: Maybe<Stat>;
  stats: Array<Stat>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionCashHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CashHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CashHistory_Filter>;
};


export type SubscriptionCashHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCollectOspUniswapLpFeeHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CollectOspUniswapLpFeeHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CollectOspUniswapLpFeeHistory_Filter>;
};


export type SubscriptionCollectOspUniswapLpFeeHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFindNftArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFindNfTsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FindNft_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FindNft_Filter>;
};


export type SubscriptionFinderInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFinderInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FinderInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FinderInfo_Filter>;
};


export type SubscriptionFinderStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFinderStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FinderStat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FinderStat_Filter>;
};


export type SubscriptionMortgageHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MortgageHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MortgageHistory_Filter>;
};


export type SubscriptionMortgageHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMultiplyHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MultiplyHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MultiplyHistory_Filter>;
};


export type SubscriptionMultiplyHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOperationHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OperationHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OperationHistory_Filter>;
};


export type SubscriptionOperationHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOsppoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOsppoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OspPool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OspPool_Filter>;
};


export type SubscriptionRedeemHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RedeemHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RedeemHistory_Filter>;
};


export type SubscriptionRedeemHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStatArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStatsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Stat_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Stat_Filter>;
};


export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type Token = {
  __typename?: 'Token';
  IsClaimed: Scalars['Boolean'];
  cnft?: Maybe<FindNft>;
  id: Scalars['ID'];
  name: Scalars['String'];
  onft?: Maybe<FindNft>;
  pool: OspPool;
  projectId: Scalars['String'];
  stars: Scalars['BigInt'];
  symbol: Scalars['String'];
  timestamp: Scalars['BigInt'];
};

export type Token_Filter = {
  IsClaimed?: InputMaybe<Scalars['Boolean']>;
  IsClaimed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  IsClaimed_not?: InputMaybe<Scalars['Boolean']>;
  IsClaimed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  cnft?: InputMaybe<Scalars['String']>;
  cnft_?: InputMaybe<FindNft_Filter>;
  cnft_contains?: InputMaybe<Scalars['String']>;
  cnft_contains_nocase?: InputMaybe<Scalars['String']>;
  cnft_ends_with?: InputMaybe<Scalars['String']>;
  cnft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_gt?: InputMaybe<Scalars['String']>;
  cnft_gte?: InputMaybe<Scalars['String']>;
  cnft_in?: InputMaybe<Array<Scalars['String']>>;
  cnft_lt?: InputMaybe<Scalars['String']>;
  cnft_lte?: InputMaybe<Scalars['String']>;
  cnft_not?: InputMaybe<Scalars['String']>;
  cnft_not_contains?: InputMaybe<Scalars['String']>;
  cnft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  cnft_not_ends_with?: InputMaybe<Scalars['String']>;
  cnft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_not_in?: InputMaybe<Array<Scalars['String']>>;
  cnft_not_starts_with?: InputMaybe<Scalars['String']>;
  cnft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  cnft_starts_with?: InputMaybe<Scalars['String']>;
  cnft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  onft?: InputMaybe<Scalars['String']>;
  onft_?: InputMaybe<FindNft_Filter>;
  onft_contains?: InputMaybe<Scalars['String']>;
  onft_contains_nocase?: InputMaybe<Scalars['String']>;
  onft_ends_with?: InputMaybe<Scalars['String']>;
  onft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  onft_gt?: InputMaybe<Scalars['String']>;
  onft_gte?: InputMaybe<Scalars['String']>;
  onft_in?: InputMaybe<Array<Scalars['String']>>;
  onft_lt?: InputMaybe<Scalars['String']>;
  onft_lte?: InputMaybe<Scalars['String']>;
  onft_not?: InputMaybe<Scalars['String']>;
  onft_not_contains?: InputMaybe<Scalars['String']>;
  onft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  onft_not_ends_with?: InputMaybe<Scalars['String']>;
  onft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  onft_not_in?: InputMaybe<Array<Scalars['String']>>;
  onft_not_starts_with?: InputMaybe<Scalars['String']>;
  onft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  onft_starts_with?: InputMaybe<Scalars['String']>;
  onft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<OspPool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  projectId_contains?: InputMaybe<Scalars['String']>;
  projectId_contains_nocase?: InputMaybe<Scalars['String']>;
  projectId_ends_with?: InputMaybe<Scalars['String']>;
  projectId_ends_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_gt?: InputMaybe<Scalars['String']>;
  projectId_gte?: InputMaybe<Scalars['String']>;
  projectId_in?: InputMaybe<Array<Scalars['String']>>;
  projectId_lt?: InputMaybe<Scalars['String']>;
  projectId_lte?: InputMaybe<Scalars['String']>;
  projectId_not?: InputMaybe<Scalars['String']>;
  projectId_not_contains?: InputMaybe<Scalars['String']>;
  projectId_not_contains_nocase?: InputMaybe<Scalars['String']>;
  projectId_not_ends_with?: InputMaybe<Scalars['String']>;
  projectId_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_not_in?: InputMaybe<Array<Scalars['String']>>;
  projectId_not_starts_with?: InputMaybe<Scalars['String']>;
  projectId_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  projectId_starts_with?: InputMaybe<Scalars['String']>;
  projectId_starts_with_nocase?: InputMaybe<Scalars['String']>;
  stars?: InputMaybe<Scalars['BigInt']>;
  stars_gt?: InputMaybe<Scalars['BigInt']>;
  stars_gte?: InputMaybe<Scalars['BigInt']>;
  stars_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stars_lt?: InputMaybe<Scalars['BigInt']>;
  stars_lte?: InputMaybe<Scalars['BigInt']>;
  stars_not?: InputMaybe<Scalars['BigInt']>;
  stars_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Token_OrderBy {
  IsClaimed = 'IsClaimed',
  Cnft = 'cnft',
  CnftCollectedIncomeTokenAmount = 'cnft__collectedIncomeTokenAmount',
  CnftId = 'cnft__id',
  CnftIsCnft = 'cnft__isCnft',
  CnftName = 'cnft__name',
  CnftOspToken = 'cnft__ospToken',
  CnftOwner = 'cnft__owner',
  CnftPercent = 'cnft__percent',
  CnftProjectId = 'cnft__projectId',
  CnftStars = 'cnft__stars',
  CnftSymbol = 'cnft__symbol',
  Id = 'id',
  Name = 'name',
  Onft = 'onft',
  OnftCollectedIncomeTokenAmount = 'onft__collectedIncomeTokenAmount',
  OnftId = 'onft__id',
  OnftIsCnft = 'onft__isCnft',
  OnftName = 'onft__name',
  OnftOspToken = 'onft__ospToken',
  OnftOwner = 'onft__owner',
  OnftPercent = 'onft__percent',
  OnftProjectId = 'onft__projectId',
  OnftStars = 'onft__stars',
  OnftSymbol = 'onft__symbol',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolOspIndex = 'pool__ospIndex',
  PoolOspToken = 'pool__ospToken',
  PoolToken0 = 'pool__token0',
  PoolToken1 = 'pool__token1',
  ProjectId = 'projectId',
  Stars = 'stars',
  Symbol = 'symbol',
  Timestamp = 'timestamp'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type LatestTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestTokenQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, symbol: string, name: string, timestamp: any, onft?: { __typename?: 'FindNFT', id: string } | null, cnft?: { __typename?: 'FindNFT', id: string } | null }> };

export type CollectFeeHistoriesQueryVariables = Exact<{
  cnftId: Scalars['String'];
}>;


export type CollectFeeHistoriesQuery = { __typename?: 'Query', collectOspUniswapLPFeeHistories: Array<{ __typename?: 'CollectOspUniswapLPFeeHistory', id: string, cTokenAmount: any, oTokenAmount: any, timestamp: any }> };

export type AllTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTokensQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, projectId: string, name: string, symbol: string, stars: any, IsClaimed: boolean, timestamp: any, pool: { __typename?: 'OSPPool', id: string, token0: any, token1: any }, onft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null, cnft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null }> };

export type TokensByAddressesQueryVariables = Exact<{
  addresses?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type TokensByAddressesQuery = { __typename?: 'Query', tokens: Array<{ __typename?: 'Token', id: string, projectId: string, name: string, symbol: string, stars: any, IsClaimed: boolean, timestamp: any, pool: { __typename?: 'OSPPool', id: string, token0: any, token1: any }, onft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null, cnft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null }> };

export type TokenDetailQueryVariables = Exact<{
  address: Scalars['ID'];
}>;


export type TokenDetailQuery = { __typename?: 'Query', token?: { __typename?: 'Token', id: string, projectId: string, name: string, symbol: string, stars: any, IsClaimed: boolean, timestamp: any, pool: { __typename?: 'OSPPool', id: string, token0: any, token1: any }, onft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null, cnft?: { __typename?: 'FindNFT', id: string, owner: any, collectedIncomeTokenAmount: any } | null } | null, finderStat?: { __typename?: 'FinderStat', id: string, finderCount: any } | null };

export type OperationHistoriesByUserQueryVariables = Exact<{
  user: Scalars['Bytes'];
}>;


export type OperationHistoriesByUserQuery = { __typename?: 'Query', operationHistories: Array<{ __typename?: 'OperationHistory', id: string, user: any, type: string, timestamp: any, mortgage?: { __typename?: 'MortgageHistory', id: string, user: any, ospAsset: any, inOspAmount: any, outFindAmount: any, amountOut: any, tokenOut: any, timestamp: any } | null, redeem?: { __typename?: 'RedeemHistory', id: string, user: any, ospAsset: any, inFindAmount: any, amountIn: any, tokenIn: any, outOspAmount: any, timestamp: any } | null, cash?: { __typename?: 'CashHistory', id: string, amountOut: any, ospAsset: any, outFindAmount: any, outOspPositionAmount: any, tokenOut: any, timestamp: any, user: any } | null, multiply?: { __typename?: 'MultiplyHistory', id: string, user: any, ospAsset: any, ospAmount: any, payFindAmount: any, tokenPay: any, amountNeedPay: any, AllFindAmount: any, timestamp: any } | null }> };


export const LatestTokenDocument = gql`
    query latestToken {
  tokens(first: 1, orderBy: timestamp, orderDirection: desc) {
    id
    symbol
    name
    timestamp
    onft {
      id
    }
    cnft {
      id
    }
  }
}
    `;

/**
 * __useLatestTokenQuery__
 *
 * To run a query within a React component, call `useLatestTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestTokenQuery(baseOptions?: Apollo.QueryHookOptions<LatestTokenQuery, LatestTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestTokenQuery, LatestTokenQueryVariables>(LatestTokenDocument, options);
      }
export function useLatestTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestTokenQuery, LatestTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestTokenQuery, LatestTokenQueryVariables>(LatestTokenDocument, options);
        }
export type LatestTokenQueryHookResult = ReturnType<typeof useLatestTokenQuery>;
export type LatestTokenLazyQueryHookResult = ReturnType<typeof useLatestTokenLazyQuery>;
export type LatestTokenQueryResult = Apollo.QueryResult<LatestTokenQuery, LatestTokenQueryVariables>;
export const CollectFeeHistoriesDocument = gql`
    query collectFeeHistories($cnftId: String!) {
  collectOspUniswapLPFeeHistories(
    where: {cnft: $cnftId}
    orderBy: timestamp
    orderDirection: desc
  ) {
    id
    cTokenAmount
    oTokenAmount
    timestamp
  }
}
    `;

/**
 * __useCollectFeeHistoriesQuery__
 *
 * To run a query within a React component, call `useCollectFeeHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectFeeHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectFeeHistoriesQuery({
 *   variables: {
 *      cnftId: // value for 'cnftId'
 *   },
 * });
 */
export function useCollectFeeHistoriesQuery(baseOptions: Apollo.QueryHookOptions<CollectFeeHistoriesQuery, CollectFeeHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectFeeHistoriesQuery, CollectFeeHistoriesQueryVariables>(CollectFeeHistoriesDocument, options);
      }
export function useCollectFeeHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectFeeHistoriesQuery, CollectFeeHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectFeeHistoriesQuery, CollectFeeHistoriesQueryVariables>(CollectFeeHistoriesDocument, options);
        }
export type CollectFeeHistoriesQueryHookResult = ReturnType<typeof useCollectFeeHistoriesQuery>;
export type CollectFeeHistoriesLazyQueryHookResult = ReturnType<typeof useCollectFeeHistoriesLazyQuery>;
export type CollectFeeHistoriesQueryResult = Apollo.QueryResult<CollectFeeHistoriesQuery, CollectFeeHistoriesQueryVariables>;
export const AllTokensDocument = gql`
    query allTokens {
  tokens(first: 1000) {
    id
    projectId
    name
    symbol
    stars
    pool {
      id
      token0
      token1
    }
    IsClaimed
    onft {
      id
      owner
      collectedIncomeTokenAmount
    }
    cnft {
      id
      owner
      collectedIncomeTokenAmount
    }
    timestamp
  }
}
    `;

/**
 * __useAllTokensQuery__
 *
 * To run a query within a React component, call `useAllTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTokensQuery(baseOptions?: Apollo.QueryHookOptions<AllTokensQuery, AllTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTokensQuery, AllTokensQueryVariables>(AllTokensDocument, options);
      }
export function useAllTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTokensQuery, AllTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTokensQuery, AllTokensQueryVariables>(AllTokensDocument, options);
        }
export type AllTokensQueryHookResult = ReturnType<typeof useAllTokensQuery>;
export type AllTokensLazyQueryHookResult = ReturnType<typeof useAllTokensLazyQuery>;
export type AllTokensQueryResult = Apollo.QueryResult<AllTokensQuery, AllTokensQueryVariables>;
export const TokensByAddressesDocument = gql`
    query tokensByAddresses($addresses: [ID!]) {
  tokens(first: 1000, where: {id_in: $addresses}) {
    id
    projectId
    name
    symbol
    stars
    pool {
      id
      token0
      token1
    }
    IsClaimed
    onft {
      id
      owner
      collectedIncomeTokenAmount
    }
    cnft {
      id
      owner
      collectedIncomeTokenAmount
    }
    timestamp
  }
}
    `;

/**
 * __useTokensByAddressesQuery__
 *
 * To run a query within a React component, call `useTokensByAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensByAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensByAddressesQuery({
 *   variables: {
 *      addresses: // value for 'addresses'
 *   },
 * });
 */
export function useTokensByAddressesQuery(baseOptions?: Apollo.QueryHookOptions<TokensByAddressesQuery, TokensByAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokensByAddressesQuery, TokensByAddressesQueryVariables>(TokensByAddressesDocument, options);
      }
export function useTokensByAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokensByAddressesQuery, TokensByAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokensByAddressesQuery, TokensByAddressesQueryVariables>(TokensByAddressesDocument, options);
        }
export type TokensByAddressesQueryHookResult = ReturnType<typeof useTokensByAddressesQuery>;
export type TokensByAddressesLazyQueryHookResult = ReturnType<typeof useTokensByAddressesLazyQuery>;
export type TokensByAddressesQueryResult = Apollo.QueryResult<TokensByAddressesQuery, TokensByAddressesQueryVariables>;
export const TokenDetailDocument = gql`
    query tokenDetail($address: ID!) {
  token(id: $address) {
    id
    projectId
    name
    symbol
    stars
    pool {
      id
      token0
      token1
    }
    IsClaimed
    onft {
      id
      owner
      collectedIncomeTokenAmount
    }
    cnft {
      id
      owner
      collectedIncomeTokenAmount
    }
    timestamp
  }
  finderStat(id: $address) {
    id
    finderCount
  }
}
    `;

/**
 * __useTokenDetailQuery__
 *
 * To run a query within a React component, call `useTokenDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenDetailQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useTokenDetailQuery(baseOptions: Apollo.QueryHookOptions<TokenDetailQuery, TokenDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenDetailQuery, TokenDetailQueryVariables>(TokenDetailDocument, options);
      }
export function useTokenDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenDetailQuery, TokenDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenDetailQuery, TokenDetailQueryVariables>(TokenDetailDocument, options);
        }
export type TokenDetailQueryHookResult = ReturnType<typeof useTokenDetailQuery>;
export type TokenDetailLazyQueryHookResult = ReturnType<typeof useTokenDetailLazyQuery>;
export type TokenDetailQueryResult = Apollo.QueryResult<TokenDetailQuery, TokenDetailQueryVariables>;
export const OperationHistoriesByUserDocument = gql`
    query operationHistoriesByUser($user: Bytes!) {
  operationHistories(
    where: {user: $user}
    orderBy: timestamp
    orderDirection: desc
  ) {
    id
    user
    type
    mortgage {
      id
      user
      ospAsset
      inOspAmount
      outFindAmount
      amountOut
      tokenOut
      timestamp
    }
    redeem {
      id
      user
      ospAsset
      inFindAmount
      amountIn
      tokenIn
      outOspAmount
      timestamp
    }
    cash {
      id
      amountOut
      ospAsset
      outFindAmount
      outOspPositionAmount
      tokenOut
      timestamp
      user
    }
    multiply {
      id
      user
      ospAsset
      ospAmount
      payFindAmount
      tokenPay
      amountNeedPay
      AllFindAmount
      timestamp
    }
    timestamp
  }
}
    `;

/**
 * __useOperationHistoriesByUserQuery__
 *
 * To run a query within a React component, call `useOperationHistoriesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationHistoriesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationHistoriesByUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useOperationHistoriesByUserQuery(baseOptions: Apollo.QueryHookOptions<OperationHistoriesByUserQuery, OperationHistoriesByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationHistoriesByUserQuery, OperationHistoriesByUserQueryVariables>(OperationHistoriesByUserDocument, options);
      }
export function useOperationHistoriesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationHistoriesByUserQuery, OperationHistoriesByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationHistoriesByUserQuery, OperationHistoriesByUserQueryVariables>(OperationHistoriesByUserDocument, options);
        }
export type OperationHistoriesByUserQueryHookResult = ReturnType<typeof useOperationHistoriesByUserQuery>;
export type OperationHistoriesByUserLazyQueryHookResult = ReturnType<typeof useOperationHistoriesByUserLazyQuery>;
export type OperationHistoriesByUserQueryResult = Apollo.QueryResult<OperationHistoriesByUserQuery, OperationHistoriesByUserQueryVariables>;