import { Interface } from "@ethersproject/abi";
import { Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import JSBI from "jsbi";
import { useMemo } from "react";

import ERC20ABI from "../abis/erc20.json";
import { Erc20Interface } from "../abis/types/Erc20";
import { currentChainId } from "../constants/chains";
import { nativeOnChain } from "../constants/token";
import { isAddress } from "../utils";
import {
  useMultipleContractSingleData,
  useSingleContractMultipleData,
} from "./multicall";
import useActiveWeb3React from "./useActiveWeb3React";
import { useInterfaceMulticall } from "./useContract";

const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface;
const tokenBalancesGasRequirement = { gasRequired: 185_000 };

export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: CurrencyAmount<Token> | undefined }, boolean] {
  const { chainId } = useActiveWeb3React();
  const eth = useMemo(() => nativeOnChain(currentChainId(chainId)).wrapped, [chainId])
  // validatedTokens not contain native token
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false && t?.address !== eth?.address) ?? [],
    [eth?.address, tokens]
  )
  const containsETH = useMemo(() => tokens?.some(t => t?.address === eth?.address), [eth?.address, tokens])
  const ethBalance = useNativeCurrencyBalances(useMemo(() => (containsETH ? [address] : []), [containsETH, address]))

  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map((vt) => vt.address),
    [validatedTokens]
  );

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20Interface,
    "balanceOf",
    useMemo(() => [address], [address]),
    tokenBalancesGasRequirement
  );

  const anyLoading: boolean = useMemo(
    () => balances.some((callState) => callState.loading),
    [balances]
  );
  return useMemo(
    () => {
      const ret = address && validatedTokens.length > 0
        ? validatedTokens.reduce<{
          [tokenAddress: string]: CurrencyAmount<Token> | undefined;
        }>((memo, token, i) => {
          const value = balances?.[i]?.result?.[0];
          const amount = value ? JSBI.BigInt(value.toString()) : undefined;
          if (amount) {
            memo[token.address] = CurrencyAmount.fromRawAmount(token, amount);
          }
          return memo;
        }, {})
        : {}
      if (address && containsETH && eth?.address) {
        ret[eth?.address] = ethBalance[address]?.wrapped
      }
      return [
        ret,
        anyLoading,
      ]
    },
    [address, validatedTokens, containsETH, eth?.address, anyLoading, balances, ethBalance]
  );
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0];
}

export function useNativeCurrencyBalances(
  uncheckedAddresses?: (string | undefined)[]
): {
  [address: string]: CurrencyAmount<Currency> | undefined;
} {
  const { chainId } = useActiveWeb3React();
  const multicallContract = useInterfaceMulticall();

  const validAddressInputs: [string][] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
            .map((addr) => [addr])
        : [],
    [uncheckedAddresses]
  );

  const results = useSingleContractMultipleData(
    multicallContract,
    "getEthBalance",
    validAddressInputs
  );


  return useMemo(
    () =>
      validAddressInputs.reduce<{
        [address: string]: CurrencyAmount<Currency>;
      }>((memo, [address], i) => {
        const value = results?.[i]?.result?.[0];
        if (value && chainId)
          memo[address] = CurrencyAmount.fromRawAmount(
            nativeOnChain(chainId),
            JSBI.BigInt(value.toString())
          );
        return memo;
      }, {}),
    [validAddressInputs, chainId, results]
  );
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(
    account,
    useMemo(() => [token], [token])
  )
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount<Currency> | undefined)[] {
  const tokens = useMemo(
    () => currencies?.filter((currency): currency is Token => currency?.isToken ?? false) ?? [],
    [currencies]
  )

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some((currency) => currency?.isNative) ?? false, [currencies])
  const ethBalance = useNativeCurrencyBalances(useMemo(() => (containsETH ? [account] : []), [containsETH, account]))

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency.isToken) return tokenBalances[currency.address]
        if (currency.isNative) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export default function useCurrencyBalance(
  account?: string,
  currency?: Currency
): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(
    account,
    useMemo(() => [currency], [currency])
  )[0]
}

export function useCurrencyBalanceString(account: string): string {
  return useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']?.toSignificant(3) ?? ''
}
