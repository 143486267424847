import { findServiceSignatureSplitApi as api } from "./signatureApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    signatureClaimNft: build.mutation<
      SignatureClaimNftApiResponse,
      SignatureClaimNftApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/signature/claim`,
        method: "PUT",
        body: queryArg.v1ClaimNftRequest,
      }),
    }),
    signatureNormalCreate: build.mutation<
      SignatureNormalCreateApiResponse,
      SignatureNormalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/signature/normal`,
        method: "PUT",
        body: queryArg.v1NormalCreateRequest,
      }),
    }),
    signatureOwnerCreate: build.mutation<
      SignatureOwnerCreateApiResponse,
      SignatureOwnerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/signature/owner`,
        method: "PUT",
        body: queryArg.v1OwnerCreateRequest,
      }),
    }),
    signatureGetTokenUnitPrice: build.mutation<
      SignatureGetTokenUnitPriceApiResponse,
      SignatureGetTokenUnitPriceApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/unit_price`,
        method: "PUT",
        body: queryArg.v1GetTokenUnitPriceRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedSignatureApi };
export type SignatureClaimNftApiResponse =
  /** status 200 A successful response. */ V1ClaimNftReply;
export type SignatureClaimNftApiArg = {
  v1ClaimNftRequest: V1ClaimNftRequest;
};
export type SignatureNormalCreateApiResponse =
  /** status 200 A successful response. */ V1NormalCreateReply;
export type SignatureNormalCreateApiArg = {
  v1NormalCreateRequest: V1NormalCreateRequest;
};
export type SignatureOwnerCreateApiResponse =
  /** status 200 A successful response. */ V1OwnerCreateReply;
export type SignatureOwnerCreateApiArg = {
  v1OwnerCreateRequest: V1OwnerCreateRequest;
};
export type SignatureGetTokenUnitPriceApiResponse =
  /** status 200 A successful response. */ V1GetTokenUnitPriceReply;
export type SignatureGetTokenUnitPriceApiArg = {
  v1GetTokenUnitPriceRequest: V1GetTokenUnitPriceRequest;
};
export type V1ClaimNftReply = {
  signature?: string;
};
export type V1SupportChainId =
  | "POLYGON"
  | "POLYGON_MUMBAI"
  | "GOERLI"
  | "MAINNET";
export type V1ClaimNftRequest = {
  chainId?: V1SupportChainId;
  name?: string;
  tokenAddress?: string;
  ownerAddress?: string;
  verifyId?: string;
};
export type V1NormalCreateReply = {
  stars?: string;
  deadline?: string;
  projectId?: string;
  buyNftTokenAmountMax?: string;
  buyNftFindAmount?: string;
  tokenToFindOutPath?: string;
  signature?: string;
  poolConfigIndex?: number;
  nftPercentConfigIndex?: number;
};
export type V1NormalCreateRequest = {
  chainId?: V1SupportChainId;
  name?: string;
  symbol?: string;
  selectToken?: string;
  selectFee?: string;
  sender?: string;
};
export type V1OwnerCreateReply = {
  stars?: string;
  deadline?: string;
  projectId?: string;
  tokenToFindOutPath?: string;
  signature?: string;
  poolConfigIndex?: number;
  nftPercentConfigIndex?: number;
};
export type V1OwnerCreateRequest = {
  chainId?: V1SupportChainId;
  name?: string;
  symbol?: string;
  ownerAddress?: string;
  selectToken?: string;
  selectFee?: string;
  state?: string;
};
export type V1GetTokenUnitPriceReply = {
  unitPrice?: string;
  buyNftTokenAmountMax?: string;
  buyNftFindAmount?: string;
  buyNftTokenDiscounted?: string;
  poolConfigIndex?: number;
  nftPercentConfigIndex?: number;
};
export type V1GetTokenUnitPriceRequest = {
  chainId?: V1SupportChainId;
  selectToken?: string;
  selectFee?: string;
  stars?: string;
};
export const {
  useSignatureClaimNftMutation,
  useSignatureNormalCreateMutation,
  useSignatureOwnerCreateMutation,
  useSignatureGetTokenUnitPriceMutation,
} = injectedRtkApi;
