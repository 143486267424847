import {SupportedChainId} from "./chains";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const NetworkContextName = "NETWORK";

export const DEFAULT_TXN_DISMISS_MS = 25000;

export const UNISWAP_THEGRAPH_BEGIN_BLOCKNUMBER = {
  // [SupportedChainId.POLYGON]: 33961976,
  // [SupportedChainId.POLYGON_MUMBAI]: 22908070,

  [SupportedChainId.GOERLI]: 4734393,
  [SupportedChainId.MAINNET]: 12369621,
};

export const OSP_TOKENNAME_PREFIX = process.env.REACT_APP_OSP_PREFIX || "0x"

export const MULTICALL_CONTRACT_ADDRESS = {
  // [SupportedChainId.POLYGON_MUMBAI]: "0x1F98415757620B543A52E61c46B32eB19261F984",
  // [SupportedChainId.POLYGON]: "0x1F98415757620B543A52E61c46B32eB19261F984",

  [SupportedChainId.GOERLI]: "0x1F98415757620B543A52E61c46B32eB19261F984",
  [SupportedChainId.MAINNET]: "0x1F98415757620B543A52E61c46B32eB19261F984",
}

export const FIND_FACTORY_CONTRACT_ADDRESS = {
  [SupportedChainId.GOERLI]: "0x6c7Fb6efdF19a5fe68B485E7204DfF55Cd09AC39",
  [SupportedChainId.MAINNET]: "0x6c7Fb6efdF19a5fe68B485E7204DfF55Cd09AC39",
}

export const FIND_EARN_CONTRACT_ADDRESS = {
  [SupportedChainId.GOERLI]: "0x3912265D37E764e05F2F5B331620f88e190eAbBF",
  [SupportedChainId.MAINNET]: "0x3912265D37E764e05F2F5B331620f88e190eAbBF",
}

export const FIND_MORTGAGE_CONTRACT_ADDRESS = {
  [SupportedChainId.GOERLI]: "0x6f7d2F595F47f2e6b5b1392643a311618942cee2",
  [SupportedChainId.MAINNET]: "0x6f7d2F595F47f2e6b5b1392643a311618942cee2",
}

export const FIND_NFT_CONTRACT_ADDRESS = {
  [SupportedChainId.GOERLI]: "0x00C7D9B555a755e68c599584e8960F1730f6429B",
  [SupportedChainId.MAINNET]: "0x00C7D9B555a755e68c599584e8960F1730f6429B",
}

export const OSP_INIT_EXCHANGE = "6,800,000";
export const OSP_POOL_FEE_PERCENT = "1%";
export const OSP_CNFT_PERCENT = "5%";
export const OSP_ONFT_PERCENT = "95%";
