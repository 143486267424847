import { SupportedChainId } from "../constants/chains"

export const SupportedChainId2BID: Record<SupportedChainId, number> = {
  // [SupportedChainId.POLYGON]: 0,
  // [SupportedChainId.POLYGON_MUMBAI]: 1,
  [SupportedChainId.GOERLI]: 2,
  [SupportedChainId.MAINNET]: 3,
}

export function fetchOGImg(chainId: SupportedChainId, imgId: string = "home") {
  fetch(`${process.env.REACT_APP_BACKEND_URL + '/'}v1/og/share/${imgId}?chainId=${(SupportedChainId2BID[chainId] || 3).toString()}`)
}
